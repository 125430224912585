<template>
  <div class="order-binding__content" slot="body">
    <div class="order-binding__ch has-text-centered" v-if="!isA4">
      <button class="button is-rounded is-medium" :class="{'is-primary': binding === 'paperback'}" @click="binding = 'paperback'">{{$t('book.bindingPaperback')}}</button>
      <button class="button is-rounded is-medium" :class="{'is-primary': binding === 'hardback'}" @click="binding = 'hardback'">{{$t('book.bindingHardback')}}</button>
    </div>
    <div class="order-binding__desc">
      <div class="ob-item ob-header">
        <label>{{activeItem.type}}</label>
        <p class="text-right">{{$t('checkout.labelBindingUsage', {rate: activeItem.rank})}}</p>
      </div>
      <div class="ob-item">
        <label>{{$t('checkout.labelBindingStar')}}：</label>
        <p class="text-right">
          <i class="fa fa-star text-primary" v-for="i in 4" aria-hidden="true" :key="i"></i>
          <i class="fa fa-star-half-o text-primary" v-if="activeItem.star === 4.5" aria-hidden="true"></i>
          <i class="fa fa-star text-primary" aria-hidden="true" v-else></i>
        </p>
      </div>
      <div class="ob-item">
        <label>{{$t('checkout.labelBindingPrice')}}：</label>
        <p class="text-right text-primary">{{symbol}}{{activeItem.price}} /頁</p>
      </div>
      <div class="ob-item block">
        <label>{{$t('checkout.labelBindingGuide')}}：</label>
        <p>{{activeItem.recommend}}</p>
      </div>
    </div>
    <div class="order-binding__hero">
      <div>
        <img :src="activeItem.image1" alt="">
        <p>{{$t('checkout.labelBindingPaperbackCover')}}</p>
      </div>
      <div>
        <img :src="activeItem.image2" alt="">
        <p>{{$t('checkout.labelBindingPaperbackInner')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import current from '../../../mixins/currency'
import { find } from 'lodash'
import paperback1 from '../../../../static/images/payment/bp-one.jpg'
import paperback2 from '../../../../static/images/payment/bp-two.jpg'
import hardback1 from '../../../../static/images/payment/bh-one.jpg'
import hardback2 from '../../../../static/images/payment/bh-two.jpg'
import paperbackA41 from '../../../../static/images/payment/typeset-A4-cover.jpg'
import paperbackA42 from '../../../../static/images/payment/typeset-A4-inner.jpg'
export default {
  name: '',
  props: {
    isA4: {
      type: Boolean,
      default: false
    }
  },
  mixins: [current],
  data: () => ({
    binding: 'hardback'
  }),
  computed: {
    itemDesc () {
      return [
        {
          name: 'paperback',
          type: this.$t('checkout.labelBindingPaperback'),
          rank: 54,
          star: 4.5,
          price: this.price.paperback,
          recommend: this.$t('checkout.labelBindingGuidePaper'),
          line1: this.$t('checkout.labelBindingPaperbackCover'),
          image1: paperback1,
          line2: this.$t('checkout.labelBindingPaperbackInner'),
          image2: paperback2
        },
        {
          name: 'hardback',
          type: this.$t('checkout.labelBindingHardback'),
          rank: 62,
          star: 5,
          price: this.price.hardback,
          recommend: this.$t('checkout.labelBindingGuideHardback'),
          line1: this.$t('checkout.labelBindingHardbackCover'),
          line2: this.$t('checkout.labelBindingHardbackInner'),
          image1: hardback1,
          image2: hardback2
        },
        {
          name: 'paperbackA4',
          type: this.$t('checkout.labelBindingPaperbackA4'),
          rank: 42,
          star: 4.5,
          price: this.price.paperbackA4,
          recommend: this.$t('checkout.labelBindingGuidePaper'),
          line1: this.$t('checkout.labelBindingPackagePaperbackA4'),
          image1: paperbackA41,
          line2: this.$t('checkout.labelBindingPaperbackInner'),
          image2: paperbackA42
        }
      ]
    },
    activeItem () {
      if (this.isA4) {
        return this.itemDesc[2]
      }
      return find(this.itemDesc, { name: this.binding })
    }
  },
  created () {},
  methods: {}
}
</script>

<style type="text/scss" lang="scss">
  @import '../../../styles/variables';
  .order-binding__content {
    p, label {
      margin: 0;
    }
    .order-binding__ch {
      margin-bottom: 1rem;
      display: flex;
      .button {
        flex: 1;
        padding: 1rem;
      }
      .button.is-primary {
        border-bottom: 2px solid $el-color-primary;
        color: $el-color-primary;
      }
      .button + .button {
        margin-left: .75rem;
      }
    }
    .order-binding__desc {
      padding: 1.35rem;
      margin-bottom: 1rem;
      box-shadow: 0 2px 5px rgba(0,0,0,.2);
      border-radius: 4px;
    }
    .ob-item {
      display: flex;
      font-size: 1rem;
      margin-bottom: 1.35rem;
      justify-items: center;
      label {
        display: flex;
        align-items: center;
        &:before {
          content: '';
          display: inline-block;
          height: 1em;
          width: 4px;
          background-color: $el-color-primary;
          margin-right: .75rem;
        }
      }
      p {
        flex: 1;
        margin: 0;
      }
      &.block {
        display: block;
        label {
          margin-bottom: .75rem;
        }
      }
      &.ob-header {
        label {
          background-color: $el-color-primary;
          color: white;
          font-weight: 600;
          padding-right: 2rem;
        }
        p {
          font-size: 1.15rem;
          color: #555;
        }
      }
    }
    .order-binding__hero {
      &>div {
        position: relative;
        margin-bottom: .75rem;
        overflow: hidden;
        box-shadow: 0 2px 5px rgba(0,0,0,.2);
        border-radius: 4px;
        img {
          border: 0;
          margin: 0;
          vertical-align: middle;
        }
        p {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: rgba(255, 255, 255, 0.75);
          padding: 1rem;
        }
      }
    }
  }
</style>
