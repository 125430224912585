<template>
  <div class="order-address form-group">
    <label>
      {{$t('checkout.labelAddress')}}
    </label>
    <div>
      <button class="btn plant small" @click="selectModal">{{$t('checkout.labelAddressPlaceholder')}}</button>
    </div>
    <div class="order-address__tip mt-1">
      <p class="clearfix">{{addressTip.receiver}}</p>
      <p>{{addressTip.address}}</p>
    </div>
    <modal :show="showModal">
      <div slot="body" class="order-address__content">
        <template v-if="addresses.length && $parent.addressLoaded">
          <div class="order-address__item" v-for="(item, index) in addresses" :key="index">
            <div class="item__content">
              <p class="receiver">{{item.lastName}}{{item.firstName}} {{item.phone}}</p>
              <p>{{item.country + item.state + item.city + item.line1 + item.line2}}</p>
            </div>
            <div class="item__action">
              <span class="text-primary" v-if="address.addressId === item.addressId">
                <i class="fa fa-check" aria-hidden="true"></i>
              </span>
              <span class="text-link" @click="selectItem(item)" v-else>{{$t('button.use')}}</span>
              <span class="text-link" @click="editAddress(item)">{{$t('button.edit')}}</span>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="text-center">
            <p>{{$t('address.tipEmpty')}}</p>
            <p>
              <button class="btn primary" @click="editAddress({})">{{$t('address.buttonCreateNew')}}</button>
            </p>
          </div>
        </template>
      </div>
      <div slot="footer">
        <button class="btn plant" @click="editAddress({})" v-if="addresses.length">
          {{$t('address.buttonCreateNew')}}
        </button>
        <button class="btn primary" @click="confirmAddress">{{$t('button.ok')}}</button>
      </div>
    </modal>
    <modal :show="showForm" class="address-form">
      <address-form v-if="showForm" ref="editor" :ad="activeAddress" slot="body"></address-form>
      <div slot="footer">
        <button class="btn text-primary" @click="showForm = false">{{$t('button.cancel')}}</button>
        <button class="btn primary" @click="updateAddress">{{$t('button.ok')}}</button>
      </div>
    </modal>
  </div>
</template>

<script>
import config from 'src/app.config'
import AddressForm from './AddressForm.vue'
import api from 'src/api'
import { findIndex } from 'lodash'

export default {
  name: '',
  components: { AddressForm },
  props: {
    activeAddressId: {
      type: String,
      default: ''
    },
    addresses: {
      type: Array,
      default: function () {
        return []
      }
    },
    addressTip: {
      type: Object,
      default: function () {
        return {
          receiver: '',
          address: ''
        }
      }
    }
  },
  data: () => ({
    bindings: config.bindings,
    showModal: false,
    showForm: false,
    address: {},
    activeAddress: {},
    saving: false
  }),
  computed: {},
  created () {
    if (this.activeAddressId) {
      this.address.addressId = this.activeAddressId
    }
    this.$watch('$parent.addressLoaded', (val) => {
      this.address = this.$parent.address
    })
  },
  methods: {
    selectModal () {
      this.$el.classList.remove('error-item')
      this.showModal = true
    },
    selectItem (address) {
      this.address = address
    },
    confirmAddress () {
      this.showModal = false
      this.$emit('change', this.address)
    },
    async updateAddress () {
      const { address } = this.$refs.editor
      const data = await this.validateData(address)
      this.$refs.editor.validateList = data.validateList
      if (data.state) {
        address.type = 'delivery'
        this.saving = true
        this.showForm = false
        if (address.addressId) {
          const result = await api.updateAddress(address.addressId, address)
          const activeIndex = findIndex(this.addresses, { addressId: address.addressId })
          this.$set(this.addresses, activeIndex, result)
          if (address.addressId === this.address.addressId) {
            this.address = result
            this.$emit('change', result)
          }
          this.saving = false
          this.$toast({
            message: this.$t('text.updated')
          })
        } else {
          const result = await api.createAddress(address)
          this.addresses.push(result)
          this.address = result
          this.$emit('change', result)
          this.saving = false
          this.$toast({
            message: this.$t('text.created'),
            icon: 'fa-check-circle'
          })
        }
      }
    },
    editAddress (address) {
      const activeAd = { ...address }
      delete activeAd.idNumber
      delete activeAd.customsName
      delete activeAd.customsPhone
      this.activeAddress = activeAd
      this.showForm = true
    },
    validateData (address) {
      let state = true
      const validateList = []
      // 台湾 香港 澳门，收件人必须中文
      if (address.countryCode === 'TW' || address.countryCode === 'HK' || address.countryCode === 'MO') {
        if (!/[\u4e00-\u9fa5]+/g.test(address.firstName)) {
          validateList.push('firstName')
          state = false
        }
        if (!/[\u4e00-\u9fa5]+/g.test(address.lastName)) {
          validateList.push('lastName')
          state = false
        }
      }
      Object.keys(address).map((item, val) => {
        if (item !== 'line2' && (typeof address[item] === 'string' && !address[item])) { // 验证输入信息是否为空
          validateList.push(item)
          state = false
        }
      })
      return Promise.resolve({
        state,
        validateList
      })
    }
  }
}
</script>

<style type="text/scss" lang="scss">
@import '../../../styles/variables';

@keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05)
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}

.order-address {
  margin-top: 2em;

  &.error-item {
    position: relative;
    animation-name: pulse;
    animation-duration: .5s;
    animation-fill-mode: both;

    &:after {
      content: '';
      position: absolute;
      display: inline-block;
      top: -10px;
      bottom: -10px;
      left: -10px;
      right: 20%;
      border: 1px solid $el-color-primary;
      pointer-events: none;
      border-radius: 8px;
    }
  }

  p {
    margin: 0;
  }

  .item__status i {
    font-size: 1.5rem;
    margin-right: .75rem;
    color: $el-color-primary;
  }

  .order-address__item {
    border-bottom: 1px dashed #a5a5a5;
    padding: .5rem 0;
    display: flex;
    align-items: center;

    .receiver {
      font-weight: 600;
    }
  }

  .item__content {
    flex: 1;
  }

  .item__action {
    span {
      cursor: pointer;
    }

    span + span {
      margin-left: .5rem;
    }
  }

  .i-modal.address-form .modal-content {
    background-color: white;
  }
}
</style>
