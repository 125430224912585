<template>
  <div class="container orders">
    <div class="row">
      <div class="col-xs-10 col-xs-offset-1">
        <div class="trade row">
          <div class="col-sm-6">
            <order-info :book="book" :bookLoaded="bookLoaded"></order-info>
            <div class="form-group order-binding" v-if="bookType === 'diarybook'">
              <label>{{$t('checkout.labelBinding')}}
                <small v-if="priceLoaded" class="text-info" @click="showBinding = true">
                  {{$t('checkout.labelTipBinding')}}
                </small>
              </label>
              <p>
                <template v-for="(binding, index) in bindings">
                  <label v-if="binding.default" :class="{'active': binding.name === order.binding}" :key="index">
                    <input type="radio" v-model="order.binding" name="format" :value="binding.name">
                    {{$t(binding.desc)}}
                  </label>
                </template>
              </p>
            </div>
            <order-num :number="order.quantity" @change="setOrderQuantity" v-show="order.binding !== 'pdf'"></order-num>
            <div ref="pdfaddress" class="pdf-email form-group" v-show="order.binding === 'pdf'">
              <label>{{$t('address.labelEmail')}} *</label>
              <input type="text" class="form-control" v-model.trim="order.pdfEmail">
            </div>
            <order-address :activeAddressId="address.addressId"
                           ref="address"
                           :addressTip="addressTip"
                           :addresses="addresses"
                           @change="changeAddress"
                           v-show="order.binding !== 'pdf'"></order-address>
            <div v-if="shouldIdInfoShow && order.binding !== 'pdf'">
              <label>實名信息</label>
              <div>
                <button class="btn plant small" @click="editIdInfo">點擊編輯</button>
              </div>
              <div class="mt-1">
                <span v-if="idInfo.customsName">
                  {{idInfo.customsName}}
                </span>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group order-coupon">
              <label>{{$t('checkout.labelCoupon')}}</label>
              <div v-if="!couponLoaded">優惠券加載中...</div>
              <template v-else>
                <div v-if="!coupons.length">{{$t('checkout.labelCouponEmpty')}}</div>
                <div class="info" v-else>
                  <b class="text-primary">{{couponTip}}</b>
                  <button class="btn small primary" @click="showCoupon = true">
                    <template v-if="activeCoupon.couponNo">{{$tc('checkout.buttonCheckCoupons', 2)}}</template>
                    <template v-else>{{$tc('checkout.buttonCheckCoupons', 1)}}</template>
                  </button>
                  <button class="btn small plant" @click="forgiveCoupon" v-if="activeCoupon.couponNo">
                    {{$t('checkout.buttonCancelCoupon')}}
                  </button>
                  <popup :show="showCoupon" @close="showCoupon = false">
                    <div slot="body">
                      <div class="text-center" v-if="!coupons.length">
                        {{$t('common.toast.tip.couponEmpty')}}
                      </div>
                      <coupon v-for="(coupon, index) in coupons" :available="true"
                              :active="activeCoupon.couponNo === coupon.couponNo"
                              :coupon="coupon" :key="index" @use="useCoupon"/>
                    </div>
                  </popup>
                </div>
              </template>
            </div>
            <pay-methods :method="order.payMethod" @change="seMethod"></pay-methods>
            <!--配送时间-->
            <div class="form-group" v-if="order.binding !== 'pdf'">
              <label>{{$t('checkout.labelExpress')}}</label>
              <p>
                <label class="active">{{$t('checkout.labelExpressPlaceholder')}}</label>
              </p>
            </div>
            <div class="form-group" v-else>
              <label>{{$t('checkout.labelExpress')}}</label>
              <p>
                <label class="active">{{$t('checkout.labelExpressPdf')}}</label>
              </p>
            </div>
            <!--备注-->
            <div class="form-group">
              <label>{{$t('checkout.labelNote')}}</label>
              <div class="row">
                <div class="col-xs-8">
                  <textarea class="textarea" name="note" v-model="order.note"></textarea>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>{{$t('checkout.tipTitle')}}</label>
              <div class="row">
                <div class="col-xs-10">
                  <p v-for="(i, idx) in tips" :key="'tip' + idx" v-html="(idx + 1) + '. ' + i"></p>
                  <template v-if="order.binding !== 'pdf'">
                    <p v-if="address && address.countryCode === 'TW'">
                      <b class="text-primary">因清關需要實名驗證，請提前下載易利委(EZway)APP進行註冊，否則書籍無法過關。</b>
                    </p>
                    <p v-html="$t('checkout.tipFreeExpress')"
                       v-show="bookLoaded && book.totalPage * order.quantity >= 500"></p>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="trade-price">
          <div class="text-right">
            <p>
              <label>{{$t('checkout.labelSumBook')}}：
                <strong class="text-red" :class="{'text-line': order.couponNo}">
                  {{symbol}}{{bookCost.toFixed(2)}}
                </strong>
              </label>
            </p>
            <p>
              <label>
                {{$t('checkout.labelSumDiscount')}}：
                <template>
                  - {{symbol}}{{discount.toFixed(2)}}
                </template>
              </label>
            </p>
            <p>
              <label>{{$t('checkout.labelSumExpress')}}：{{symbol}}{{deliveryCost.toFixed(2)}}</label>
            </p>
          </div>
          <div class="order-pay text-right">
            {{$t('checkout.labelSumTotal')}}:
            <strong class="text-red total-cost">{{symbol}}{{totalCost}}</strong>
            <button class="btn primary"
                    :class="{'is-disabled': !addressLoaded || !priceLoaded || !bookLoaded || isReTypeset}"
                    @click="startCheck">
              <span>{{$t('checkout.buttonSubmit')}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <modal :show="showIdInfo">
      <div slot="body">
        <div>
          <h3>實名信息</h3>
          <div class="form-group">
            <label>收件姓名</label>
            <input type="text" class="form-control" placeholder="請填寫正確中文姓名（必填）"
                   v-model.trim="idInfo.customsName">
          </div>
          <div class="form-group">
            <label>身份證號</label>
            <input type="text" class="form-control" placeholder="請填寫正確的身份ID（必填）"
                   v-model.trim="idInfo.idNumber">
          </div>
          <div class="form-group">
            <label>手機號</label>
            <input type="text" class="form-control" placeholder="請填寫可聯繫的手機號/電話號碼（必填）"
                   v-model="idInfo.customsPhone">
          </div>
        </div>
        <p class="mt-1">
          <b
            class="text-primary text-small">溫馨提示：因為海關對快件的收方信息嚴格審查，台灣用戶請您在實名認證平台（易利委APP）實名認證，否則無法寄件，以上信息不會用於其它地方，請放心填寫。</b>
        </p>
        <p class="text-center">
          <button class="btn default" @click="showIdInfo = false">
            取消
          </button>
          <button class="btn primary" @click="updateIdInfo">
            完成
          </button>
        </p>
      </div>
    </modal>
    <modal :show="showModal" id="stripe-modal" ref="stripe">
      <stripe-modal slot="body" :price="`${symbol}${totalCost}`"
                    @close="showModal = false"
                    @lack="showModal = false"
                    @paid="stripePaid"/>
    </modal>
    <i-dialog :show="showBinding" @close="showBinding = false">
      <div class="">
        <order-binding :isA4="isA4"></order-binding>
      </div>
    </i-dialog>
    <toast :message="paidLoading.text" :value="paidLoading.show" :lock="true" closeable>
      <div class="loading-icon"></div>
    </toast>
  </div>
</template>

<script>
import OrderInfo from '../Trade/OrderInfo.vue'
import OrderAddress from './OrderAddress.vue'
import OrderNum from './OrderNum.vue'
import PayMethods from '../Trade/PayMethods.vue'
import Coupon from 'src/components/layout/Coupon'
import OrderBinding from '../Trade/OrderBinding.vue'
import StripeModal from '../Trade/StripeModal.vue'
import { find, orderBy, isEmpty, filter, cloneDeep, isNaN } from 'lodash'
import load from 'src/_utils/load-script'
import api from 'src/api/'
import generateCover from 'src/_utils/generateCover'
import config from 'src/app.config'
import currency from 'src/mixins/currency'
import { mapGetters, mapActions } from 'vuex'
import Cookie from 'js-cookie'

export default {
  name: 'check',
  mixins: [currency],
  components: {
    OrderInfo,
    OrderAddress,
    OrderNum,
    PayMethods,
    StripeModal,
    Coupon,
    OrderBinding
  },
  data () {
    return {
      bookType: 'diarybook',
      book: {},
      bindings: config.bindings,
      order: {
        quantity: 1,
        binding: 'hardback',
        payMethod: 'stripe',
        delivery: '3-day-delivery',
        note: '',
        pdfEmail: ''
      },
      stripeKey: '',
      prices: {
        book: {},
        delivery: {}
      }, // 当前地区价格表 {book:{}, delivery: {}}
      priceTables: {},
      albumTables: {}, // 相片书价格表
      calendarTables: {}, // 台历价格表
      coupons: [], // 当前全部个人优惠信息
      activeCoupon: {}, //
      addresses: [], // 全部地址
      address: {}, // 最新使用地址
      isA4: false,
      isReTypeset: false,
      showMenu: false,
      showTrade: false,
      bookLoaded: false,
      priceLoaded: false,
      couponLoaded: false,
      addressLoaded: false,
      showModal: false,
      showCoupon: false,
      showBinding: false,
      showIdInfo: false,
      idInfo: {},
      loading: {
        text: '支付中',
        visible: false
      },
      paidLoading: {
        text: '',
        show: false
      }
    }
  },
  watch: {
    order: {
      handler (newVal, oldVal) {
        if (newVal.pdfEmail !== '') {
          const addressEl = this.$refs.pdfaddress
          if (addressEl.classList.contains('error-item')) {
            addressEl.classList.remove('error-item')
          }
        }
      },
      deep: true
    },
    address (state, preState) {
      if (!isEmpty(state)) {
        const locale = this.$store.state.locale
        const addressEl = this.$refs.address.$el
        if (addressEl.classList.contains('error-item')) {
          addressEl.classList.remove('error-item')
        }
        if (state.countryCode !== locale && state.countryCode) {
          console.log(`当前地址变化为：${state.countryCode}`)
          this.setLocale(state.countryCode)
          this.fetchPrice(state.countryCode.toLowerCase())
        }
      }
    },
    bookLoaded (state) {
      if (state && this.couponLoaded) {
        this.autoSetA4Coupon()
      }
    },
    couponLoaded (state) {
      if (state && this.bookLoaded) {
        this.autoSetA4Coupon()
      }
    },
    showIdInfo (value) {
      if (!value) {
        if (!this.address.idNumber) {
          this.idInfo = {}
        } else {
          const {
            customsName,
            customsPhone,
            idNumber
          } = this.address
          this.idInfo = {
            customsName,
            customsPhone,
            idNumber
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'language', 'locale'
    ]),
    supportMethods () {
      return [
        {
          type: 'stripe',
          label: '信用卡支付',
          visible: true
        },
        {
          type: 'paypal',
          label: 'PayPal 支付',
          visible: true
        },
        {
          type: 'alipay',
          label: '支付宝',
          visible: false
        }
      ]
    },
    shouldIdInfoShow () {
      return this.order.binding !== 'pdf' && this.address?.countryCode === 'TW'
    },
    perCost () { // 单品价格
      if (!this.priceLoaded) {
        return 0
      }
      if (!this.bookLoaded) {
        return 0
      }
      if (this.bookType === 'photobook') {
        const {
          template,
          design
        } = this.book
        const { cover } = design
        const material = cover.material || 'fabric'
        const priceMaps = this.albumTables[template][material]
        return Number(priceMaps.cover) + Number(priceMaps.inner) * this.book.totalPage
      } else if (this.bookType === 'calendar') {
        const { template } = this.book
        return Number(this.calendarTables[template])
      }
      const priceMaps = this.prices.book[this.order.binding]
      const pageLimit = config.minBookPagesLimitation
      const page = this.book.totalPage ? (this.book.totalPage < pageLimit ? pageLimit : this.book.totalPage) : 0
      return Number(priceMaps.cover) + Number(priceMaps.inner) * page
    },
    bookCost () {
      if (!this.priceLoaded) {
        return 0
      }
      if (!this.bookLoaded) {
        return 0
      }
      // 计算单品价格
      try {
        let page = this.book.totalPage ? this.book.totalPage * this.order.quantity : 0
        if (this.bookType === 'photobook') {
          const {
            template,
            design
          } = this.book
          const { cover } = design
          const material = cover.material || 'fabric'
          const priceMaps = this.albumTables[template][material]
          return Number(priceMaps.cover) * this.order.quantity + Number(priceMaps.inner) * page
        } else if (this.bookType === 'calendar') {
          const { template } = this.book
          return Number(this.calendarTables[template]) * this.order.quantity
        }
        const typeset = this.book.typeset.substr(0, 2)
        const priceMaps = this.priceTables[typeset][this.order.binding]
        const isVoucher = !isEmpty(this.activeCoupon) && this.activeCoupon.type === 'voucher'
        const pageLimit = isVoucher && this.activeCoupon.rules.minPages ? Math.max(
          this.activeCoupon.rules.minPages,
          config.minBookPagesLimitation
        ) : config.minBookPagesLimitation
        page = Math.max(page, pageLimit)
        return Number(priceMaps.cover) * this.order.quantity + Number(priceMaps.inner) * page
      } catch (e) {
        console.error(e)
        return 0
      }
    },
    deliveryCost () {
      if (!this.priceLoaded) {
        return 0
      }
      // 添加临时活动 总页数500页 免运费
      if (this.book.totalPage * this.order.quantity >= 500) {
        return 0
      }

      // 免邮选项 ，台历产品暂时免邮
      if (this.couponLoaded &&
        ((!isEmpty(this.activeCoupon) && this.activeCoupon.rules.freeDelivery) || this.bookType === 'calendar')) {
        return 0
      }
      if (this.order.binding === 'pdf') {
        return this.prices.delivery['0-day-delivery'] - 0
      } else {
        return this.prices.delivery[this.order.delivery] - 0
      }
    },
    discount () {
      let typeset, priceMaps, page, pageLimit
      if (this.couponLoaded && !isEmpty(this.activeCoupon)) {
        switch (this.activeCoupon.type) {
          case 'discount':
            return this.bookCost * (1 - this.activeCoupon.value)
          case 'voucher':
            typeset = this.book.typeset.substr(0, 2)
            priceMaps = this.priceTables[typeset][this.order.binding]
            page = this.book.totalPage ? this.book.totalPage * this.order.quantity : 0
            pageLimit = this.activeCoupon.rules.minPages ? Math.max(
              this.activeCoupon.rules.minPages,
              config.minBookPagesLimitation
            ) : config.minBookPagesLimitation
            page = Math.max(page, pageLimit)
            page = Math.min(page, this.activeCoupon.value)
            return (priceMaps.cover - 0) + (priceMaps.inner - 0) * page
          case 'cash':
            return this.activeCoupon.value
          case 'bulk':
            if (this.order.quantity >= 2) {
              return Math.floor(this.order.quantity / 2) * (1 - this.activeCoupon.value) * this.perCost
            } else {
              return 0
            }
          default:
            return 0
        }
      } else {
        return 0
      }
    },
    totalCost () {
      return (this.bookCost + this.deliveryCost - this.discount).toFixed(2)
    },
    couponTip () {
      if (this.couponLoaded) {
        if (this.activeCoupon.couponNo) {
          const expressTip = this.activeCoupon.rules.freeDelivery ? this.$t('coupons.labelFreeDelivery') : ''
          const prefix = this.activeCoupon.rules.typeset ? this.$t(
            'checkout.tipCouponTypeset',
            { typeset: this.activeCoupon.rules.typeset }
          ) : ''
          switch (this.activeCoupon.type) {
            case 'discount':
              return this.activeCoupon.value ? `${prefix} ${this.$t(
                'checkout.tipCouponDiscount',
                { count: 100 - this.activeCoupon.value * 100 }
              )} ${expressTip}` : prefix + this.$t('checkout.labelCouponFree')
            case 'voucher':
              return `${prefix} ${this.$t(
                'checkout.tipCouponVoucher',
                { page: this.activeCoupon.value }
              )} + ${expressTip}`
            case 'cash':
              return `${prefix} ${this.symbol}${this.activeCoupon.value} + ${expressTip}`
            case 'bulk':
              return `${prefix} ${this.$t(
                'checkout.tipCouponBulk',
                { count: Math.round(100 - this.activeCoupon.value * 100) }
              )}`
          }
        }
        // 检查当前优惠券列表内，实际可用数
        const availableCoupons = this.coupons.filter(item => {
          const itemDiscount = item.type === 'discount' && item.value === 1 // 当前后端显示折扣 1，隐藏优惠
          const itemAvailable = !item.rules.groupAvaliable // 当前团购券不可用
          if (!(item.scope === 'group_buying' && (itemDiscount || itemAvailable))) {
            return item
          }
        })
        return availableCoupons.length ? this.$t(
          'checkout.labelCouponPlaceholder',
          { count: availableCoupons.length }
        ) : this.$t('checkout.labelCouponEmpty')
      }
      return this.$t('checkout.labelCouponEmpty')
    },
    addressTip () {
      if (isEmpty(this.address)) {
        return {
          receiver: '',
          address: ''
        }
      }
      return {
        receiver: `${this.address.lastName + ' ' + this.address.firstName} ${this.address.phone}`,
        address: `${this.address.country + this.address.state + this.address.city + this.address.line1 +
        this.address.line2}`
      }
    },
    tips () {
      let lines = [
        'checkout.tipLine1',
        'checkout.tipLine2',
        'checkout.tipLine3',
        'checkout.tipLine4',
        'checkout.tipLine5',
        'checkout.tipLine6'
      ]
      if (this.order.binding === 'pdf') {
        lines = lines.slice(0, 3)
      }
      return lines.map(k => this.$te(k) ? this.$t(k) : '').filter(Boolean)
    }
  },
  created () {
    const bid = this.$route.params.bid || this.$route.query.bid
    const method = this.$route.query.method
    const locale = (this.locale || 'TW').toLowerCase()
    this.bookType = this.$route.query.bookType || 'diarybook'
    this.fetchBook(bid)
    this.fetchPrice(locale)
    this.fetchOrderAddress()
    this.fetchCoupons()
    if (find(this.supportMethods, { type: method })) {
      this.order.payMethod = method
    }
    window.ga('require', 'ecommerce') // 加载电子商务插件
  },
  mounted () {
    this.initStripe()
  },
  methods: {
    ...mapActions([
      'setLocale'
    ]),
    autoSetA4Coupon () {
      if (this.bookType === 'diarybook') {
        const typeset = this.book.typeset.toUpperCase()
        const restCoupons = this.coupons.filter(coupon => {
          return coupon.bookType === 'all' || (coupon.bookType === 'diarybook' && !coupon.rules.typeset) ||
            (coupon.rules.typeset && typeset.includes(coupon.rules.typeset.toUpperCase()))
        })
        if (restCoupons.length) {
          this.activeCoupon = restCoupons[0]
        }
      } else if (this.bookType === 'photobook' || this.bookType === 'calendar') {
        const restCoupons = this.coupons.filter(item => item.bookType === this.bookType || item.bookType === 'all')
        if (restCoupons.length) {
          this.activeCoupon = restCoupons[0]
        }
      }
    },
    forgiveCoupon () {
      this.activeCoupon = {}
      Cookie.set('check_tip', true)
    },
    fetchBook (bid) {
      const bookType = this.bookType || 'diarybook'
      const requestHandler = {
        diarybook: api.fetchBook,
        photobook: api.fetchPhotoBook,
        calendar: api.fetchCalendar
      }
      const request = requestHandler[bookType]
      request(bid).then(data => {
        if (!data || data.errors) {
          return
        }
        if (this.bookType === 'diarybook') {
          const {
            retypesetMonths,
            typeset,
            source
          } = data
          const retypesetPages = retypesetMonths[typeset]
          this.isReTypeset = retypesetPages.length
          if (retypesetPages.length) {
            this.$confirm({
              title: '書籍排版中，請前往預覽檢查內容後再支付',
              cancel: false,
              okText: '前往預覽',
              onCancel: (modal) => {
                modal.close()
                this.$router.replace({
                  name: 'preview',
                  query: {
                    sid: source,
                    bid
                  }
                })
              }
            })
          }
        }
        if (this.bookType === 'diarybook') {
          data.template = find(config.templates, { name: data.typeset }).desc
        } else if (this.bookType === 'photobook') {
          data.template = data.template.name
          if (data.template === 'album') {
            this.order.delivery = 'album-delivery'
          }
        } else if (this.bookType === 'calendar') {
          data.template = data.template.name
          data.totalPage = 13
          data.design = {
            ...data.cover,
            cover: data.cover,
            template: data.cover.coverTemplate
          }
        }
        data.coverUrl = generateCover({
          design: data.design,
          chapters: data.currentChapters,
          isCalendar: this.bookType === 'calendar'
        })
        if (/A4/gi.test(data.typeset)) {
          this.order.binding = 'paperback'
          this.isA4 = true
        }
        data.type = this.bookType
        this.book = data
        this.bookLoaded = true
      })
    },
    fetchPrice (locale) {
      this.priceLoaded = false
      api.fetchPrice(locale).then(data => {
        this.priceLoaded = true
        this.prices = this.handleLocalePrices(data)
      })
    },
    fetchOrderAddress () {
      this.addressLoaded = false
      api.fetchOrderAddresses('delivery').then(data => {
        this.addressLoaded = true
        const addresses = filter(data, { countryCode: this.locale })
        if (addresses.length) {
          const address = orderBy(addresses, ['updatedAt'], ['desc'])[0]
          this.address = address
          if (address.idNumber) {
            this.idInfo = {
              idNumber: address.idNumber,
              customsName: address.customsName,
              customsPhone: address.customsPhone
            }
          }
        }
        this.addresses = data
      })
    },
    fetchCoupons () {
      api.fetchAllCoupon().then(data => {
        let coupons = data.map(item => {
          item.coupon.rest = item.accountCoupon.rest || item.coupon.rest
          item.coupon.used = item.accountCoupon.used || item.coupon.used
          return item.coupon
        })
        if (coupons.length) {
          coupons = coupons.filter(item => (item.scope !== 'group_buying' || item.rules.groupAvaliable) && Date.now() >=
            new Date(item.beginAt).getTime())
        }
        const sortKeys = ['all', 'diarybook', 'photobook', 'calendar']
        const sortObj = coupons.reduce((sortMap, coupon) => {
          if (!sortMap[coupon.bookType]) {
            sortMap[coupon.bookType] = []
          }
          sortMap[coupon.bookType].push(coupon)
          return sortMap
        }, {})
        coupons = sortKeys.reduce((list, key) => {
          if (sortObj[key]) {
            list = list.concat(sortObj[key])
          }
          return list
        }, [])
        this.coupons = coupons
        this.couponLoaded = true
      })
    },
    editIdInfo () {
      this.showIdInfo = true
    },
    updateIdInfo () {
      if (!this.idInfo.customsName || !this.idInfo.idNumber || !this.idInfo.customsPhone) {
        this.$toast({
          message: '請完善個人信息'
        })
        return
      }
      // 实名信息姓名中文限制
      if (!/[\u4e00-\u9fa5]+/g.test(this.idInfo.customsName)) {
        this.$toast({
          message: '請填寫正確中文姓名'
        })
        return
      }
      // 台湾电话校验
      const { countryCode } = this.address
      if (countryCode === 'TW' && !/^[0]?[1-9]{1}[0-9]{2}[0-9]{3}[0-9]{3}$/.test(this.idInfo.customsPhone)) {
        this.$confirm({
          title: '為保證您能順利收到快遞，請填寫正確手機/電話號碼',
          cancel: false
        })
        return
      }
      api.updateIdInfo({
        ...this.idInfo,
        countryCode
      }).then(data => {
        if (data.errors) {
          this.$toast({
            message: data.errors.msg
          })
          return
        }
        this.address = { ...this.address, ...this.idInfo }
        this.$nextTick(() => {
          this.showIdInfo = false
        })
      })
    },
    postItem () {
      window.ga('ecommerce:addTransaction', {
        id: this.$route.query.bid,
        revenue: this.bookCost + this.deliveryCost,
        shipping: this.deliveryCost,
        tax: '0',
        currency: this.currency
      })
    },
    // 初始化 stripe
    initStripe () {
      load('https://js.stripe.com/v2/', (err, script) => {
        if (err) {
          this.$toast({
            message: this.$t('checkout.tipStripeInitFailed'),
            position: 'bottom'
          })
          return
        }
        api.fetchKey('stripe').then(data => {
          if (!data || data.errors) {
            const errorCode = data.errors ? data.errors.code : ''
            const message = errorCode ? this.$t('checkout.tipStripeKeyFailed') + errorCode : this.$t(
              'checkout.tipStripeKeyFailed')
            this.$toast({
              message,
              position: 'bottom'
            })
            return
          }
          this.stripeKey = data
        })
      })
    },
    // 处理本地价格
    handleLocalePrices (prices) {
      const bookPrice = find(prices, { priceType: 'book' })
      const deliveryPrice = find(prices, { priceType: 'delivery' })
      const photoBookPrice = find(prices, { priceType: 'photobook' }) // 相片书价格
      const calendarPrice = find(prices, { priceType: 'calendar' }) // 台历价格表
      if (isEmpty(bookPrice.value) || isEmpty(deliveryPrice.value)) {
        this.$toast({
          message: this.$t('checkout.tipPriceFailed'),
          position: 'bottom'
        })
        return {}
      }
      const a5Price = bookPrice.value.a5
      const priceTables = cloneDeep(bookPrice.value)
      Object.keys(priceTables).map(item => {
        Object.keys(priceTables[item]).map(price => {
          priceTables[item][price].inner = priceTables[item][price].inner[200]
        })
      })
      this.priceTables = priceTables
      if (photoBookPrice) {
        // console.log(photoBookPrice)
        this.albumTables = photoBookPrice.value
      }
      if (calendarPrice) {
        this.calendarTables = calendarPrice.value
      }
      try {
        Object.keys(a5Price).map((item) => {
          a5Price[item].inner = a5Price[item].inner[200] // 暂时取200的价格
        })
      } catch (e) {
        console.error(e)
      }
      return {
        book: a5Price,
        delivery: deliveryPrice.value
      }
    },
    editAddress () {
      const query = Object.assign({}, this.$route.query, { ads: 1 })
      if (!isEmpty(this.address)) query.aid = this.address.addressId
      this.$router.push({
        path: this.$route.path,
        query: query
      })
    },
    changeAddress (address) { // 注意切换地区应该产生价格变化
      if (!isEmpty(address)) {
        this.address = { ...address }
      }
    },
    setOrderQuantity (num) {
      this.order.quantity = num
      if ((this.activeCoupon.maxPages && this.activeCoupon.maxBooks) && (num > this.activeCoupon.maxBooks)) {
        this.activeCoupon = {}
      }

      // 免单券用户限制一本
      if (!isEmpty(this.activeCoupon) && !this.activeCoupon.value && num > 1) {
        this.activeCoupon = {}
        this.$toast({
          message: '免單優惠券僅限一本書籍',
          duration: 3500
        })
        return
      }
      if (num <= 1 && this.activeCoupon.type === 'bulk') {
        this.activeCoupon = {}
        this.$toast({
          message: '當前優惠券僅支持兩本以上訂單',
          duration: 3500
        })
      }
    },
    seMethod (method) {
      this.order.payMethod = method
    },
    startCheck () {
      if (!this.bookLoaded || !this.addressLoaded || !this.priceLoaded || this.isReTypeset) {
        return
      }
      // 判断下单页数，不足则弹出提示
      if (this.book.totalPage < config.minBookPagesLimitation && this.bookType === 'diarybook') {
        this.$confirm({
          title: this.$t('checkout.tipPageLimit'),
          cancelText: this.$t('button.cancel'),
          okText: this.$t('button.ok'),
          onOk: (modal) => {
            modal.close()
            this.startOrder()
          }
        })
      } else {
        this.startOrder()
      }
    },
    startOrder () {
      const order = Object.assign({}, this.order, {
        bid: this.$route.query.bid,
        tz: 8,
        couponNo: this.activeCoupon.couponNo || '',
        addressId: this.address.addressId || '',
        currency: this.currency,
        countryCode: this.$store.state.locale || 'TW',
        locale: this.$store.state.language,
        payDevice: 'pc',
        printType: this.bookType === 'diarybook' ? this.book.typeset : '',
        bookType: this.bookType
      })
      if (this.bookType !== 'diarybook') {
        delete order.binding
      }
      if (order.binding !== 'pdf') {
        if (!order.addressId) {
          this.$toast({
            message: this.$t('checkout.tipAddressFailed')
          })
          const addressEl = this.$refs.address.$el
          if (!addressEl.classList.contains('error-item')) {
            addressEl.classList.add('error-item')
          }
          return
        }
        // 地址完善提醒
        if (!this.address.phone.trim() || !this.address.country || !this.address.city || !this.address.state ||
          !this.address.line1) {
          this.$toast({
            message: this.$t('checkout.tipAddressUncompleted')
          })
          return
        }
      } else {
        if (!order.pdfEmail) {
          this.$toast({
            message: this.$t('checkout.tipAddressFailed')
          })
          const addressEl = this.$refs.pdfaddress
          if (!addressEl.classList.contains('error-item')) {
            addressEl.classList.add('error-item')
          }
          return
        }
        order.addressId = ''
        order.delivery = '0-day-delivery'
      }

      // 当前存在优惠券未使用，提示选择优惠
      const isTipped = typeof Cookie.get('check_tip') === 'undefined'
      if (isTipped && isEmpty(this.activeCoupon) && this.coupons.length) {
        Cookie.set('check_tip', true)
        this.$confirm({
          title: this.$t('checkout.tipCouponExist'),
          okText: this.$t('checkout.btnCouponCheck'),
          cancelText: this.$t('checkout.btnGoPay'),
          onOk: (modal) => {
            modal.close()
            this.showCoupon = true
          },
          onCancel: (modal) => {
            modal.close()
            this.startCheck()
          }
        })
        return
      }

      if ((this.address.countryCode === 'TW') &&
        (!this.idInfo.customsName || !this.idInfo.idNumber)) {
        this.showIdInfo = true
        return
      }

      // 当前订单免费，免邮。设置免单订单
      if (!isNaN(this.totalCost) && !Number(this.totalCost)) {
        order.free = true
        order.payMethod = 'stripe'
      } else if (order.payMethod === 'stripe' && !order.token) {
        this.showModal = true
        window.Stripe.setPublishableKey(this.stripeKey) // 初始stripe 配置
        return
      }

      this.paidLoading = {
        text: this.$t('checkout.tipPaymentConfirming'),
        show: true
      }
      api.postOrder(order).then(data => {
        this.paidLoading.show = false
        if (!data || data.errors) {
          const message = data.errors ? data.errors.message + '<br/>' + '错误码：' + data.errors.code : this.$t(
            'checkout.tipPaymentFailed')
          if (data.errors.code === 44513) {
            this.$confirm({
              title: '支付失敗，換張卡試試吧',
              cancel: false,
              okText: '知道了',
              onCancel: (modal) => {
                modal.close()
                this.showModal = true
              }
            })
          } else if (data.errors.code === 43012) {
            this.$toast({
              message: '書籍正在排版，請稍後刷新重試',
              position: 'bottom',
              duration: 5000
            })
          } else {
            this.$toast({
              message,
              position: 'bottom',
              duration: 5000
            })
          }
          this.order.token = ''
          return
        }

        if (order.payMethod === 'paypal' || order.payMethod === 'alipay') { // PayPal ，Alipay支付跳转
          window.location.href = data.approvalUrl
          return
        }
        // stripe 本地支付成功处理
        this.$toast({
          message: this.$t('checkout.tipPaymentSucceed'),
          icon: 'fa-check-circle'
        })
        window.ga('ecommerce:addItem', {
          id: data.orderId,
          name: this.book.design.title,
          category: this.order.binding,
          quantity: this.order.quantity,
          currency: this.currency
        })
        // GA监控支付，提交电子商务
        window.ga('ecommerce:send')
        this.$router.push({
          name: 'order-status',
          query: {
            order_id: data.orderId,
            type: 'stripe'
          }
        })
      })
    },
    stripePaid (token) {
      this.order.token = token
      this.showModal = false
      this.startCheck()
    },
    useCoupon (coupon) {
      if (coupon.type === 'discount' && !coupon.value && this.order.quantity > 1) {
        this.$toast({
          message: '免單優惠券僅限一本書籍',
          duration: 3500
        })
        return
      }
      if (coupon.bookType !== 'all' && this.bookType !== coupon.bookType) {
        this.$toast({
          message: '當前書籍類型暫不支持使用該優惠券'
        })
        this.showCoupon = false
        return
      }
      // 处理相片书优惠券类型
      if (coupon.bookType === 'photobook' && coupon.rules.templates && coupon.rules.templates[0] !==
        this.book.template) {
        this.$toast({
          message: '當前相片书暫不支持使用該優惠券'
        })
        this.showCoupon = false
        return
      }
      if (coupon.type === 'bulk' && this.order.quantity < 2) {
        this.$toast({
          message: this.$t('checkout.tipCouponQuantityDecline')
        })
        return
      }
      let typeset = this.book.typeset || ''
      typeset = typeset.toUpperCase()
      if (coupon.rules.typeset && (typeset.indexOf(coupon.rules.typeset) === -1)) {
        this.$toast({
          message: this.$t('checkout.tipCouponTypesetDecline')
        })
        return
      }

      this.activeCoupon = coupon
      this.showCoupon = false
      this.activeValue = {
        type: coupon.type,
        value: coupon.value
      }
    }
  }
}
</script>

<style type="text/scss" lang="scss">
@import '../../../styles/layout/Trade';
@import '../../../styles/variables';

.pdf-email {
  margin-top: 2em;

  &.error-item {
    position: relative;
    animation-name: pulse;
    animation-duration: .5s;
    animation-fill-mode: both;

    &:after {
      content: '';
      position: absolute;
      display: inline-block;
      top: -10px;
      bottom: -10px;
      left: -10px;
      right: -10px;
      border: 1px solid $el-color-primary;
      pointer-events: none;
      border-radius: 8px;
    }
  }
}
</style>
